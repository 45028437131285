import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import { useAttendanceConext } from "page/diemDanh/Context";
import ImportBoardingButton from "page/diemDanh/component/ImportBoardingButton";
import React, { memo, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "util/routes";
import styles from "../styles.module.css";

const LABEL_DATE_PICKER = {
  DATE: "Ngày",
  WEEK: "Tuần",
  MONTH: "Tháng",
};

const FORMAT_DATE_PICKER = {
  DATE: "DD/MM/YYYY",
  WEEK: "wo",
  MONTH: "MM/YYYY",
};

const PICKER_DATE = {
  DATE: "date",
  WEEK: "week",
  MONTH: "month",
};

const boardingOptions = [
  {
    title: "Có",
    value: true,
  },
  {
    title: "Không",
    value: false,
  },
];

const Filter = memo(() => {
  const location = useLocation();
  const {
    gradeId,
    classId,
    selectedDate,
    sectionId,
    lessonId,
    roomId,
    boardingId,

    gradeList,
    classList,
    sectionList,
    lessonList,
    roomList,

    setGradeId,
    setClassId,
    setSelectedDate,
    setSectionId,
    setLessonId,
    setRoomId,
    setBoardingId,
  } = useAttendanceConext();

  const url = useMemo(() => {
    return `${location.pathname}${location.search}`;
  }, [location.pathname, location.search]);

  const labelDatePicker = useMemo(() => {
    switch (url) {
      case routes.ATTENDANCE:
        return LABEL_DATE_PICKER.DATE;
      case routes.ATTENDANCE_BY_WEEK:
        return LABEL_DATE_PICKER.WEEK;
      case routes.ATTENDANCE_BY_MONTH:
        return LABEL_DATE_PICKER.MONTH;
      default:
        return LABEL_DATE_PICKER.DATE;
    }
  }, [url]);

  const formatDatePicker = useMemo(() => {
    switch (url) {
      case routes.ATTENDANCE:
        return FORMAT_DATE_PICKER.DATE;
      case routes.ATTENDANCE_BY_WEEK:
        return FORMAT_DATE_PICKER.WEEK;
      case routes.ATTENDANCE_BY_MONTH:
        return FORMAT_DATE_PICKER.MONTH;
      default:
        return FORMAT_DATE_PICKER.DATE;
    }
  }, [url]);

  const pickerDate = useMemo(() => {
    switch (url) {
      case routes.ATTENDANCE:
        return PICKER_DATE.DATE;
      case routes.ATTENDANCE_BY_WEEK:
        return PICKER_DATE.WEEK;
      case routes.ATTENDANCE_BY_MONTH:
        return PICKER_DATE.MONTH;
      default:
        return PICKER_DATE.DATE;
    }
  }, [url]);

  const renderGradeClass = useMemo(() => {
    switch (url) {
      case routes.ATTENDANCE:
      case routes.ATTENDANCE_BY_WEEK:
      case routes.ATTENDANCE_BY_MONTH:
      case routes.ATTENDANCE_BY_LUNCH:
        return true;
      default:
        return false;
    }
  }, [url]);

  const renderSectionLesson = useMemo(() => {
    switch (url) {
      case routes.ATTENDANCE:
      case routes.ATTENDANCE_BY_WEEK:
      case routes.ATTENDANCE_BY_MONTH:
        return true;
      default:
        return false;
    }
  }, [url]);

  const filterClassName = useMemo(() => {
    let clsName = styles.wrapperFilterDate;

    if (
      url === routes.ATTENDANCE_BY_LUNCH ||
      url === routes.ATTENDANCE_BY_BOARDING
    ) {
      clsName = styles.wrapperFilterBoarding;
    }
    return clsName;
  }, [url]);

  return (
    <div className={`mb-5 ${filterClassName}`}>
      {renderGradeClass && (
        <>
          <InfoFieldSelect
            label="Khối"
            data={gradeList}
            value={gradeId}
            setValue={setGradeId}
            direction="vertical"
            // className={`flex-1 mr-4 max-[912px]:w-1/2`}
          />

          <InfoFieldSelect
            label="Lớp"
            data={classList}
            value={classId}
            setValue={setClassId}
            direction="vertical"
          />
        </>
      )}

      {url === routes.ATTENDANCE_BY_BOARDING && (
        <InfoFieldSelect
          label="Phòng"
          data={roomList}
          value={roomId}
          setValue={setRoomId}
          direction="vertical"
        />
      )}
      <InfoFieldDatePicker
        label={labelDatePicker}
        format={formatDatePicker}
        value={selectedDate}
        setValue={setSelectedDate}
        placeholder="Chọn thời gian"
        direction="vertical"
        picker={pickerDate}
      />
      {renderSectionLesson && (
        <>
          <InfoFieldSelect
            label="Buổi"
            data={sectionList}
            value={sectionId}
            setValue={setSectionId}
            direction="vertical"
            valueTextName="title"
            allowClear={false}
          />
          {/* <InfoFieldSelect
            label="Tiết"
            data={lessonList}
            value={lessonId}
            setValue={setLessonId}
            direction="vertical"
            allowClear={false}
          /> */}
        </>
      )}
      {url === routes.ATTENDANCE_BY_LUNCH && (
        <InfoFieldSelect
          label="Bán trú"
          data={boardingOptions}
          value={boardingId}
          setValue={setBoardingId}
          direction="vertical"
          valueFieldName="value"
          valueTextName="title"
          allowClear={true}
        />
      )}
      {url === routes.ATTENDANCE_BY_BOARDING && <ImportBoardingButton />}
    </div>
  );
});

export default Filter;
