import moment from "moment";
import AttendanceProvider from "page/diemDanh/Context";
import Inner from "page/diemDanh/Inner";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getClassListByGrade,
  getGradeList,
  getLessonListBySession,
  getSessionList,
} from "util/APIs";
import { SESSION_ID } from "util/constants";

const DiemDanh = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;

  const [isRefresh, setIsRefresh] = useState(false);

  const [grade, setGradeId] = useState(null);
  const [classId, setClassId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [section, setSection] = useState(null);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [boardingId, setBoardingId] = useState(null);
  const [boardingCheck, setBoardingCheck] = useState(false);

  const [gradeList, setGradeIdList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [sectionList, setSectionList] = useState(null);
  const [sectionListFull, setSectionListFull] = useState(null);
  const [lessonList, setLessonList] = useState([]);
  const [roomList, setRoomList] = useState([]);

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  const handleChangeGradeId = (value) => {
    setGradeId(value);
    setClassId(null);
  };

  const handleChangeSectionId = (value) => {
    setSection(value);
    setCurrentLesson(null);
  };

  const getGrade = useCallback(async () => {
    const params = {
      orderBy: "name",
    };
    const res = await getGradeList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setGradeIdList(data);
      if (data?.length > 0) {
        setGradeId(data[0]?.id);
      }
    }
  }, [accountId]);

  const getClass = useCallback(
    async (gradeId) => {
      const params = {
        orderBy: "name",
      };
      const res = await getClassListByGrade(accountId, gradeId, params);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setClassList(data);
        if (data?.length > 0) {
          setClassId(data[0]?.id);
        }
      }
    },
    [accountId]
  );

  const getSection = useCallback(async () => {
    const params = {
      // orderBy: "title",
    };
    const res = await getSessionList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setSectionListFull([...data]);
      const filtered = data.filter((item) => item.id !== SESSION_ID.BUOI_TRUA);
      setSectionList(filtered);
      if (data?.length > 0) {
        setSection(filtered[0]?.id);
      }
    }
  }, [accountId]);

  const getLessonList = useCallback(async () => {
    const params = {
      "filters[sessions.id][$eq]": section,
      orderBy: "name",
    };
    const res = await getLessonListBySession(accountId, params);
    if (res?.status === 200) {
      const data = res?.data.data;
      setLessonList(data);

      if (data[0]?.id) {
        setCurrentLesson(data[0].id);
      }
    }
  }, [accountId, section]);

  useEffect(() => {
    getGrade();
  }, [getGrade]);

  useEffect(() => {
    if (grade) {
      getClass(grade);
    }
  }, [grade, getClass]);

  useEffect(() => {
    getSection();
  }, [getSection]);

  // useEffect(() => {
  //   if (section) {
  //     getLessonList();
  //   }
  // }, [getLessonList, section]);

  return (
    <AttendanceProvider
      value={{
        isRefresh,

        gradeId: grade,
        classId,
        selectedDate,
        sectionId: section,
        lessonId: currentLesson,
        roomId,
        boardingId,
        boardingCheck,

        gradeList,
        classList,
        sectionList,
        lessonList,
        roomList,

        setGradeId: handleChangeGradeId,
        setClassId,
        setSelectedDate: handleSetSelectedDate,
        setSectionId: handleChangeSectionId,
        setLessonId: setCurrentLesson,
        setRoomId,
        setBoardingId,

        setRoomList,
        setIsRefresh,
        setBoardingCheck,
      }}
    >
      <Inner />
    </AttendanceProvider>
  );
});

export default DiemDanh;
