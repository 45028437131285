import { get } from "util/APICaller";
import { API_URL } from "util/constant";

const exportConductAssessment = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/export-conduct-assessment`,
    params
  );
};

const exportStudentsViolateRules = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/students-violate-rules/export`,
    params
  );
};

export const studentsViolateRulesService = {
  exportConductAssessment,
  exportStudentsViolateRules,
};
