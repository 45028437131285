import ExportConductAssessmentButton from "page/violateManagementPage/component/ExportConductAssessmentButton";
import ExportDisciplinaryButton from "page/violateManagementPage/component/ExportDisciplinaryButton";
import ExportViolationButton from "page/violateManagementPage/component/ExportViolationButton";
import React, { memo } from "react";
import styles from "../styles.module.css";

const Header = memo(() => {
  return (
    <div className={styles.wrapperHeader}>
      <ExportConductAssessmentButton />
      <ExportDisciplinaryButton />
      <ExportViolationButton />
    </div>
  );
});

export default Header;
