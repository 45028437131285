import { Button } from "antd";
import ExportViolationModal from "page/violateManagementPage/modal/ExportViolationModal";
import React, { memo, useCallback, useState } from "react";
import styles from "../styles.module.css";

const ExportViolationButton = memo(() => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <Button
        type="primary"
        onClick={handleOpenModal}
        className={`flex justify-center items-center ${styles.searchBtn}`}
      >
        <span className={`${styles.searchBtnText}`}>Xuất báo cáo vi phạm</span>
      </Button>
      <ExportViolationModal
        isModalOpen={isOpen}
        onCloseModal={handleOpenModal}
      />
    </>
  );
});

export default ExportViolationButton;
