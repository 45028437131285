import CusTable from "common/CusTable";
import IsMobile from "hook/isMobile";
import Filter from "page/diemDanh/component/Filter";
import TotalStudents from "page/diemDanh/component/TotalStudents";
import AttendanceStatus from "page/diemDanh/status/AttendanceStatus";
import WorkflowState from "page/diemDanh/tabs/dateAttendance/column/WorkflowState";
import React, { memo, useMemo } from "react";
import styles from "../../styles.module.css";
// import FileList, { FileItem } f rom "common/FilePreviewer/FileList";
// import IconFile from "asset/icons/IconFile";

const Inner = memo(
  ({
    totalAttendanceStudents,
    totalStudents,
    data,
    tableLoading,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    totalItems,
    // scroll,
  }) => {
    const isUsingMobile = IsMobile();

    // const viewFile = (values) => {
    //   const lst = values ? JSON.parse(values) : [];
    //   if (lst?.length) {
    //     const formattedFiles = lst?.map?.((item) => ({
    //       name: item.title,
    //       url: item.url,
    //       type: item.mimetype,
    //     }));

    //     return (
    //       <FileList attachments={formattedFiles}>
    //         <FileItem {...formattedFiles[0]}>
    //           {({ onOpen }) => {
    //             return (
    //               <div onClick={onOpen}>
    //                 <div className={`${styles.viewFile}`}>
    //                   <IconFile />
    //                 </div>
    //               </div>
    //             );
    //           }}
    //         </FileItem>
    //       </FileList>
    //     );
    //   }
    // };

    const columns = useMemo(() => {
      let columnLst = [
        {
          title: "STT",
          align: "center",
          key: "STT",
          render: (value, record, index) =>
            (currentPage - 1) * pageSize.value + index + 1,
          width: "5%",
        },
        {
          title: "Họ Tên",
          align: "left",
          key: `students`,
          dataIndex: "full_name",
          render: (text, record) => <div>{text}</div>,
          width: "10%",
          className: styles.fixedCol,
        },
        // {
        //   title: "Files",
        //   align: "center",
        //   width: "5%",
        //   dataIndex: "files",
        //   render: (text, record) => viewFile(text),
        // },
        {
          title: "Điểm danh",
          key: "workflow_state",
          align: "center",
          width: "20%",
          render: (value, record) => (
            <WorkflowState value={value} record={record} />
          ),
        },
      ];
      if (isUsingMobile) {
        columnLst = columnLst.filter((item) => item.dataIndex !== "dob");
      }
      return columnLst;
    }, [currentPage, isUsingMobile, pageSize.value]);

    return (
      <div className="px-8">
        <Filter />
        <AttendanceStatus />
        <TotalStudents
          totalAttendanceStudents={totalAttendanceStudents}
          totalStudents={totalStudents}
        />
        <CusTable
          rowKey="id"
          data={data}
          // columns={columns}
          columns={columns}
          loading={tableLoading}
          havePagination={totalItems > pageSize.value}
          rowClassName="bg-white attendance-row"
          page={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalItems={totalItems}
          scroll={{ x: "500px", y: 480 }}
          // scroll={scroll}
        />
      </div>
    );
  }
);

export default Inner;
