// @ts-nocheck
// @ts-ignore
import { Table } from "antd";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import { Notification } from "common/Notification";
import CustomBreadcrumb from "common/breadcrumb/CustomBreadcrumb";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  createOneRegisterNotebookRecord,
  getClassListByGrade,
  getClassListByTeacher,
  getGradeList,
  getRegisterNotebookList,
  getRegisterNotebookRecords,
  getRegisterNotebookRecordsByTeacher,
  updateOneRegisterNotebookRecord,
} from "util/APIs";
import { TEACHING_MANAGEMENT, routes } from "util/routes";
import { afternoonLessons, morningLessons, renderLessonTitle } from "./common";
import EditLessonModal from "./component/EditLessonModal";
import styles from "./styles.module.css";
import { checkIsRoleAdmin, formatCellValue } from "util/common";
import { is1920OrMore } from "util/constant";
import IsMobile from "hook/isMobile";
import MobileHeader from "mobile/layout/header/MobileHeader";
import { HEADER_TYPE } from "mobile/util/mobileConstant";
import FileList, { FileItem } from "common/FilePreviewer/FileList";
import IconFile from "asset/icons/IconFile";


function TeachingManagement() {
  const isUsingMobile = IsMobile();
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const roles = userInfo?.user?.roles;

  const [morningData, setMorningData] = useState([]);
  const [afternoongData, setAfternoonData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [classId, setClassId] = useState(null);
  const [classList, setClassList] = useState([]);
  const [gradeId, setGradeId] = useState(null);
  const [gradeList, setGradeList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const [currentLesson, setCurrentLesson] = useState(null);
  const [registerNotebookId, setRegisterNotebookId] = useState(null);

  const responsive = {
    setResponsiveCol: () => {
      if (!is1920OrMore) {
        columns[1].width = 120;
        columns[2].width = 120;
        columns[3].width = 120;
      }
    },
    nodeWrapperWidth: () => {
      if (is1920OrMore) return "560px";
      if (!is1920OrMore) return "560px";
    },
    tableScroll: () => {
      if (is1920OrMore) return {};
      else
        return {
          x: 1000,
          y: 490,
        };
    },
  };
  const viewFile = (values) => {
    if (values === null) {
      return "";
    }
    const lst = values ? JSON.parse(values) : [];
    if (lst?.length > 0) {
      const formattedFiles = lst?.map?.((item) => ({
        name: item.title,
        url: item.url,
        type: item.mimetype,
      }));
      return (
        <FileList attachments={formattedFiles}>
          <FileItem {...formattedFiles[0]}>
            {({ onOpen }) => {
              return (
                <div onClick={onOpen}>
                  <div className={`${styles.viewFile}`}>
                    <IconFile />
                  </div>
                </div>
              );
            }}
          </FileItem>
        </FileList>
      );
    }
    if (lst?.length === 0) {
      return "Đã ký sổ";
    }
  };
  const columns = useMemo(() => {
    const handleRow = (data) => {
      setIsModalOpen(!isModalOpen);
      let session_id
      if (data.lesson_code.endsWith("s")) {
        session_id = 1
      }
      if (data.lesson_code.endsWith("c")) {
        session_id = 2
      }
      setCurrentLesson({ ...data, classId: classId, date: moment(selectedDate).format("YYYY-MM-DD"), sessions: session_id });
    };

    return [
      {
        title: "Tiết",
        dataIndex: "lesson_code",
        key: "lesson_code",
        width: "75px",
        fixed: "left",
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {renderLessonTitle[cell]}
          </div>
        ),
      },
      {
        title: "Môn học",
        dataIndex: "subjects",
        key: "subjects",
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {formatCellValue(cell?.[0]?.name)}
          </div>
        ),
      },
      {
        title: "Tên bài học",
        dataIndex: "name",
        key: "name",
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {formatCellValue(cell)}
          </div>
        ),
      },
      {
        title: "Học sinh vắng",
        // dataIndex: "absent_students",
        dataIndex: "students",
        key: "absent_students",
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {cell?.length
              ? cell?.map?.((item) => item?.full_name)?.join(", ")
              : formatCellValue(null)}
          </div>
        ),
      },
      {
        title: "Kiểm tra đầu giờ",
        key: "oral_tests",
        dataIndex: "oral_tests",
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {cell?.length
              ? cell?.map?.((item) => item?.name)
              : formatCellValue(null)}
          </div>
        ),
      },
      {
        title: "Học sinh vi phạm",
        dataIndex: "students_violate_rules",
        key: "students_violate_rules",
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {cell?.length
              ? cell?.map?.((item) => (
                <div>
                  {item?.students?.[0]?.full_name}
                  {" - "}
                  {item?.register_notebook_rules?.map?.((r) => r?.name)?.join(", ")}
                </div>
              ))
              : formatCellValue(null)}
          </div>
        ),
      },
      {
        title: "Nhận xét",
        dataIndex: "lesson_evaluations",
        key: "lesson_evaluations",
        // render: (cell) => <div>{cell?.[0]?.name}</div>,
        render: (cell, record) => (
          <div onClick={() => handleRow(record)} className={styles.cell}>
            {formatCellValue(cell?.[0]?.name)}
          </div>
        ),
      },
      {
        title: "Chữ ký",
        dataIndex: ["signatures", 0, "files"],
        render: (text, record) => viewFile(text),
        width: "10%",
      },
    ];
  }, [isModalOpen, classId]);
  responsive.setResponsiveCol();

  const fetchData = useCallback(async () => {
    // const registerNotebookId = selectedClass;
    // const foundItem = classOptions.find(item => item.value === selectedClass);
    let params = {};
    if (selectedDate) {
      params = {
        "filters[classes.id]": classId,
        "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      };
    }
    setLoading(true);
    try {
      const response = await getRegisterNotebookRecordsByTeacher(accountId, userInfo?.user?.id, params);
      if (response?.status === 200) {
        const data = response?.data?.data;
        const morningLst = [
          { lesson_code: morningLessons.tiet1_s },
          { lesson_code: morningLessons.tiet2_s },
          { lesson_code: morningLessons.tiet3_s },
          { lesson_code: morningLessons.tiet4_s },
          { lesson_code: morningLessons.tiet5_s },
        ];
        const mLst = morningLst.map((i) => {
          if (
            data?.find(
              (item) => item?.lessons?.[0]?.lesson_code === i.lesson_code
            ) !== -1
          ) {
            return {
              ...i,
              ...data?.find(
                (item) => item?.lessons?.[0]?.lesson_code === i.lesson_code
              ),
            };
          }
          return i;
        });
        const afternoonLst = [
          { lesson_code: afternoonLessons.tiet1_c },
          { lesson_code: afternoonLessons.tiet2_c },
          { lesson_code: afternoonLessons.tiet3_c },
          { lesson_code: afternoonLessons.tiet4_c },
          { lesson_code: afternoonLessons.tiet5_c },
        ];
        const aLst = afternoonLst.map((i) => {
          if (
            data?.find(
              (item) => item?.lessons?.[0]?.lesson_code === i.lesson_code
            ) !== -1
          ) {
            return {
              ...i,
              ...data?.find(
                (item) => item?.lessons?.[0]?.lesson_code === i.lesson_code
              ),
            };
          }
          return i;
        });
        setMorningData(mLst);
        setAfternoonData(aLst);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [selectedDate, registerNotebookId, accountId]);

  const fetchRegisterNotebook = useCallback(async () => {
    try {
      const params = {
        "filters[classes.id]": classId,
      };
      const response = await getRegisterNotebookList(accountId, params);
      if (response?.status === 200) {
        const data = response?.data?.data?.[0];
        setRegisterNotebookId(data?.id);
      }
    } catch (e) { }
  }, [accountId, classId]);

  const fetchClassListByTeacher = useCallback(async () => {
    try {
      const teacherId = userInfo?.staff?.id;
      const response = await getClassListByTeacher(accountId, teacherId);
      if (response?.status === 200) {
        const { data } = response?.data;
        setClassList(data);
        if (data?.length > 0) {
          setClassId(data[0]?.id);
        }
      }
    } catch (e) { }
  }, [accountId, userInfo?.staff?.id]);

  const fetchGradeList = useCallback(async () => {
    try {
      const res = await getGradeList(accountId);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setGradeList(data);
        if (data?.length > 0) {
          setGradeId(data[0]?.id);
        }
      }
    } catch (error) { }
  }, [accountId]);

  const fetchClassList = useCallback(async () => {
    const res = await getClassListByGrade(accountId, gradeId);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setClassList(data);

      if (data?.length > 0) {
        setClassId(data[0]?.id);
      }
    }
  }, [accountId, gradeId]);

  useEffect(() => {
    if (checkIsRoleAdmin(roles)) {
      fetchGradeList();
    } else {
      fetchClassListByTeacher();
    }
  }, [fetchClassListByTeacher, fetchGradeList, roles]);

  useEffect(() => {
    if (gradeId) {
      fetchClassList();
    }
  }, [fetchClassList, gradeId]);

  useEffect(() => {
    if (classId) {
      fetchRegisterNotebook();
    }
  }, [fetchRegisterNotebook, classId]);

  useEffect(() => {
    if (registerNotebookId) {
      fetchData();
    }
  }, [fetchData, registerNotebookId]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const handleSubmit = useCallback(
    async (values) => {
      const payload = {
        name: values.name,
        comment: values.comment,
        subjects: [values.subject],
        oral_tests: values.oral_tests_data,
        students_violate_rules: values.students_violate_rules_data,
        lesson_evaluations: [values.lesson_evaluation],
        // students: values.students,
        students: values.absent_students,
        date: selectedDate,
        signed: values.signed,
        signatures: [values.signature]
      };
      if (values.oral_tests_data.length) {
        let testLst = [];
        values.oral_tests_data.forEach((item) => {
          if (item?.id && item?.score) {
            testLst.push(item);
          }
        });
        payload.oral_tests = testLst;
      }
      if (values.students_violate_rules_data.length) {
        let violateLst = [];
        values.students_violate_rules_data.forEach((item) => {
          if (item?.id && item?.rules?.length) {
            violateLst.push(item);
          }
        });
        payload.students_violate_rules = violateLst;
      }
      if (currentLesson?.id) {
        const body = {
          data: payload,
        };
        const response = await updateOneRegisterNotebookRecord(
          accountId,
          currentLesson?.id,
          body
        );
        if (response?.status === 200) {
          Notification.sendSuccess("Cập nhật thành công");
          handleCancel();
          fetchData();
        } else {
          Notification.sendError("Đã có lỗi xảy ra");
        }
      } else {
        payload.register_notebooks = [registerNotebookId];
        payload.lessons = [values.lesson_id];
        const body = {
          data: payload,
        };
        const response = await createOneRegisterNotebookRecord(accountId, body);
        if (response?.status === 200) {
          Notification.sendSuccess("Cập nhật thành công");
          handleCancel();
          fetchData();
        } else {
          Notification.sendError("Đã có lỗi xảy ra");
        }
      }
    },
    [
      accountId,
      currentLesson,
      fetchData,
      handleCancel,
      registerNotebookId,
      selectedDate,
    ]
  );

  const handleSelectDate = useCallback((value) => {
    setSelectedDate(moment(value).toISOString());
  }, []);

  const handleSetSelectedDate = (value) => {
    const temp = moment(value).format();
    setSelectedDate(temp);
  };

  return (
    <div className="h-full flex flex-col">
      {isUsingMobile ? (
        <MobileHeader
          type={HEADER_TYPE.middleTitle}
          title="Sổ đầu bài điện tử"
          haveNotiButton={false}
        />
      ) : (
        <CustomBreadcrumb
          crumbs={[TEACHING_MANAGEMENT, routes.REGISTER_NOTEBOOKS]}
        />
      )}
      <div className="bg-white p-6 rounded-lg">
        {/* <div className="flex gap-2 mBottom12 max-w-screen-xl:w-full xl:w-1/2"> */}
        <div className={styles.wrapperFilters}>
          {checkIsRoleAdmin(roles) ? (
            <>
              <InfoFieldSelect
                label="Khối"
                data={gradeList}
                value={gradeId}
                setValue={setGradeId}
                direction="vertical"
              />

              <InfoFieldSelect
                label="Lớp"
                data={classList}
                value={classId}
                setValue={setClassId}
                direction="vertical"
              />
            </>
          ) : (
            <InfoFieldSelect
              label="Lớp"
              data={classList}
              value={classId}
              setValue={setClassId}
              direction="vertical"
              className="flex-1"
            />
          )}
          <InfoFieldDatePicker
            label={"Chọn ngày"}
            value={selectedDate}
            setValue={handleSetSelectedDate}
            placeholder="Chọn thời gian"
            direction="vertical"
            className="flex-1"
            picker={"date"}
          />
          {/* <FilterClass
            value={selectedClass}
            options={classOptions}
            onChangeClass={handleSelectClass}
          />
          <FilterDate onChangeDate={handleSelectDate} /> */}
        </div>
        {/* <CusTimetable
          data={morningData}
          title="Buổi sáng"
          customRenderCell={renderCell}
        /> */}
        <div className="text-lg font-medium mTop24">
          Buổi sáng <span className="text-gray-400 text-base">(5 tiết)</span>
        </div>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={morningData}
          columns={columns}
          pagination={false}
          scroll={responsive.tableScroll()}
        />
        <div className="text-lg font-medium mTop24">
          Buổi chiều <span className="text-gray-400 text-base">(5 tiết)</span>
        </div>
        <Table
          rowKey="id"
          loading={loading}
          dataSource={afternoongData}
          columns={columns}
          pagination={false}
          scroll={responsive.tableScroll()}
        />
        {/* <CustomTimeTable
          scheduleList={morningData}
          cellMode={TIMETABLE_CELL_MODE.teacherViewSubject}
          period={PERIOD.morning.code}
          isDisplayDate={false}
          reloadScheduleList={reloadScheduleList}
          isAllowToUpdate={isAllowToUpdate}
          isAllowToDelete={isAllowToDelete}
      /> */}
        <EditLessonModal
          isOpen={isModalOpen}
          toggle={handleCancel}
          onFinish={handleSubmit}
          initialData={currentLesson}
        />
      </div>
    </div>
  );
}

export default TeachingManagement;
