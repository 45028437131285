import { get, post } from "util/APICaller";
import { API_URL } from "util/constant";

const exportAttendanceAbsent = (accountId, params) => {
  return get(
    `${API_URL}/accounts/${accountId}/attendances/export_absent`,
    params
  );
};

const createAttendanceForClass = (accountId, body) => {
  return post(
    `${API_URL}/accounts/${accountId}/attendances/class`,
    body
  );
};

export const attendanceService = {
  exportAttendanceAbsent,
  createAttendanceForClass,
};
