import { createContext, useContext } from "react";

const AttendanceContext = createContext({
  isRefresh: false,
  attendanceData: [],

  gradeId: null, // Khối
  classId: null, // Lớp
  selectedDate: null, // Ngày
  sectionId: null, // Buổi
  lessonId: null, // Tiết
  roomId: null, // phòng
  boardingId: null, // bán trú
  boardingCheck: false, // check đã bấm nút lưu bán trú chưa

  gradeList: [],
  classList: [],
  sectionList: [],
  lessonList: [],
  roomList: [],
  boardingList: [],

  setGradeId: () => {},
  setClassId: () => {},
  setSelectedDate: () => {},
  setSectionId: () => {},
  setLessonId: () => {},
  setRoomId: (value) => {},
  setBoardingId: () => {},

  setRoomList: (value) => {},
  setIsRefresh: (value) => {},
  setBoardingCheck: (value) => {},
});

export const useAttendanceConext = () => useContext(AttendanceContext);

const AttendanceProvider = AttendanceContext.Provider;

export default AttendanceProvider;
