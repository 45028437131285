import { Modal, Spin } from "antd";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import moment from "moment";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { exportStudentViolate } from "util/APIs";
import styles from "../styles.module.css";
import { isFromDateBeforeToDate } from "util/common";

const ExportViolationModal = memo(({ isModalOpen, onCloseModal }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [loading, setLoading] = useState(false);
  const [fromDateExport, setFromDateExport] = useState(null);
  const [toDateExport, setToDateExport] = useState(null);

  const handleCancel = () => {
    setFromDateExport(null);
    setToDateExport(null);
    onCloseModal();
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const params = {
        "filters[date][$gte]": moment(fromDateExport).format("YYYY-MM-DD"),
        "filters[date][$lte]": moment(toDateExport).format("YYYY-MM-DD"),
      };
      const res = await exportStudentViolate(accountId, params);
      if (res.status === 200) {
        window.open(res.data.data, "_blank");
      }
    } catch (error) {
      console.error("Lỗi xuất dữ liệu:", error);
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  const handleSelectDateFromExport = (value) => {
    const temp = moment(value).format("YYYY-MM-DD");
    setFromDateExport(temp);
  };

  const handleSelectDateToExport = (value) => {
    const temp = moment(value).format("YYYY-MM-DD");
    setToDateExport(temp);
  };

  const disabledBtn = useMemo(() => {
    return !isFromDateBeforeToDate(fromDateExport, toDateExport);
  }, [fromDateExport, toDateExport]);

  useEffect(() => {
    if (isModalOpen) {
      setFromDateExport(moment().subtract(1, "months").format("YYYY-MM-DD"));
      setToDateExport(moment().format("YYYY-MM-DD"));
    }
  }, [isModalOpen]);

  return (
    <Modal
      style={{ marginTop: "-70px" }}
      width={1000}
      title="Xuất báo cáo vi phạm"
      open={isModalOpen}
      onOk={loading ? null : handleOk}
      destroyOnClose={true}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: disabledBtn,
        className: disabledBtn ? styles.disabledBtn : "",
      }}
      maskClosable={false}
    >
      <Spin spinning={loading} tip="Đang xuất dữ liệu. Vui lòng đợi...">
        <div className="flex flex-col gap-4">
          <InfoFieldDatePicker
            label={"Từ Ngày"}
            value={fromDateExport}
            setValue={handleSelectDateFromExport}
            placeholder="Chọn thời gian"
            direction="vertical"
            className="flex-1"
            picker={"date"}
          />
          <InfoFieldDatePicker
            label={"Tới ngày"}
            value={toDateExport}
            setValue={handleSelectDateToExport}
            placeholder="Chọn thời gian"
            direction="vertical"
            className="flex-1"
            picker={"date"}
          />
          {!isFromDateBeforeToDate(fromDateExport, toDateExport) && (
            <span className="text-red-500">
              * Ngày bắt đầu không thể lớn hơn ngày kết thúc
            </span>
          )}
        </div>
      </Spin>
    </Modal>
  );
});

export default ExportViolationModal;
