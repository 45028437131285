import { post } from "util/APICaller";
import { API_URL } from "util/constant";

const importStudent = (accountId, body) => {
  return post(`${API_URL}/accounts/${accountId}/students/import`, body);
};

const importBoardingStudent = (accountId, body) => {
  return post(
    `${API_URL}/accounts/${accountId}/boarding_students/import`,
    body
  );
};

export const studentService = {
  importStudent,
  importBoardingStudent,
};
