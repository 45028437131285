import moment from "moment";
import { useAttendanceConext } from "page/diemDanh/Context";
import Inner from "page/diemDanh/tabs/dateAttendance/Inner";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAttendanceListByClass,
  getNumberOfAttendancesByCard,
} from "util/APIs";
import { TABLE_PAGE_SIZES } from "util/constant";

const DateAttendance = memo(() => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const {
    selectedDate,
    classId,
    sectionId,
    // lessonId,
    isRefresh,
    setIsRefresh,
  } = useAttendanceConext();

  const [tableLoading, setTableLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZES[0]);
  const [totalAttendanceStudents, setTotalAttendancesStudents] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);

  const fetchData = useCallback(
    async (page = null) => {
      if (!sectionId) return;
      try {
        if (page === null) page = currentPage;
        setTableLoading(true);
        const original = {
          "pagination[pageSize]": pageSize.value,
          "pagination[page]": page,
        };

        setAttendanceData([]);
        const params = {
          ...original,
          "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
          "filters[sessions.id][$in]": sectionId,
          // "filters[lessons.id][$in]": lessonId,
        };
        if (classId) {
          const res = await getAttendanceListByClass(
            accountId,
            classId,
            params
          );
          if (res?.status === 200) {
            res?.data.sort((a, b) => a.stt - b.stt);
            if (res?.status === 200) {
              setAttendanceData(res?.data);
              let resByDay = res.data;
              console.log("resByDay", resByDay);
              const presentLst = resByDay.filter(
                (item) => item.attendances[0]?.workflow_state === "present"
              );
              setTotalAttendancesStudents(presentLst.length);
            }
          }
        }
      } catch (error) {
      } finally {
        setTableLoading(false);
      }
    },
    [accountId, classId, currentPage, pageSize.value, sectionId, selectedDate]
  );

  const getNumberOfStudents = useCallback(async () => {
    if (!classId) return;
    const params = {
      // "filters[type][$eq]": "class",
      "filters[sessions.id][$eq]": sectionId,
      "filters[date][$eq]": moment(selectedDate).format("YYYY-MM-DD"),
      // "filters[lessons.id][$in]": lessonId,
    };
    try {
      const response = await getNumberOfAttendancesByCard(
        accountId,
        classId,
        params
      );
      if (response?.status === 200) {
        setTotalStudents(response.data.data.totalStudents);
      }
    } catch (err) {}
  }, [sectionId, selectedDate, classId, accountId]);

  useEffect(() => {
    getNumberOfStudents();
  }, [getNumberOfStudents]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isRefresh) {
      fetchData();
      setIsRefresh(false);
    }
  }, [fetchData, isRefresh, setIsRefresh]);

  return (
    <Inner
      totalAttendanceStudents={totalAttendanceStudents}
      totalStudents={totalStudents}
      data={attendanceData}
      tableLoading={tableLoading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalItems={totalItems}
    />
  );
});

export default DateAttendance;
