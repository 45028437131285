import {
  certificate,
  certificateActive,
  personalIcon,
  personalIconActive,
  schoolIcon,
  schoolIconActive,
  shieldIcon,
  shieldIconActive,
  sidebarAccount,
  sidebarAccountActive,
  sidebarCash,
  sidebarCashActive,
  sidebarInfoGate,
  sidebarInfoGateActive,
  sidebarInfrastructure,
  sidebarInfrastructureActive,
  sidebarOverview,
  sidebarOverviewActive,
  sidebarSchool,
  sidebarSchoolActive,
  sidebarSetting,
  sidebarSettingActive,
  sidebarStudent,
  sidebarStudentActive,
  sidebarTeacher,
  sidebarTeacherActive,
  sidebarTeaching,
  sidebarTeachingActive,
  timetable,
  timetableActive,
} from "asset/img/Images";
import {
  STUDENT_MANAGEMENT,
  TEACHING_MANAGEMENT,
  PARENTS_MANAGEMENT,
  routes,
} from "./routes";

export const SCHOOL_SUBSIDEBAR_ITEMS = [
  {
    id: "school",
    icon: sidebarTeaching,
    activeIcon: sidebarTeachingActive,
    name: "Thông tin trường",
  },
  // {
  //   id: "news",
  //   icon: sidebarNews,
  //   activeIcon: sidebarNewsActive,
  //   name: "Tin tức - Sự kiện",
  // },
  // {
  //   id: "mail",
  //   icon: sidebarInfrastructure,
  //   activeIcon: sidebarInfrastructureActive,
  //   name: "Hỗ trợ",
  // },
];

export const PERSONAL_INFORMATION_SUBSIDEBAR_ITEMS = [
  {
    id: "personalPage",
    icon: personalIcon,
    activeIcon: personalIconActive,
    name: "Thông tin cá nhân",
  },
  {
    id: "accountPage",
    icon: shieldIcon,
    activeIcon: shieldIconActive,
    name: "Thông tin tài khoản",
  },
];

// Khai báo tên cho sidebar, breadcrumb
export const LINK_INFO = [
  // {
  //   link: "/home",
  //   title: "Trang chủ",
  //   breadcrumbTitle: "Trang chủ",
  // },
  // Quản lý trường học
  {
    link: "/school",
    title: "Quản lý trường học",
    breadcrumbTitle: "Trường học",
  },
  {
    link: "/sms",
    title: "Quản lý tin nhắn",
    breadcrumbTitle: "Tin nhắn",
  },
  {
    link: "/school-year",
    title: "Niên khóa",
    breadcrumbTitle: "Niên khóa",
  },
  {
    link: "/grade",
    title: "Khối",
    breadcrumbTitle: "Khối",
  },
  {
    link: "/class",
    title: "Lớp",
    breadcrumbTitle: "Lớp",
  },
  // Quản lý giảng dạy
  {
    link: "/subject",
    title: "Môn học",
    breadcrumbTitle: "Môn học",
  },
  {
    link: "/curriculum",
    title: "Chương trình học",
    breadcrumbTitle: "Chương trình học",
  },
  {
    link: "/curriculum-distribution",
    title: "Phân phối chương trình",
    breadcrumbTitle: "Phân phối chương trình",
  },
  {
    link: "/teaching-distribution",
    title: "Phân công giảng dạy",
    breadcrumbTitle: "Phân công giảng dạy",
  },
  {
    link: "/timetable-information",
    title: "Thời khóa biểu",
    breadcrumbTitle: "Thời khóa biểu",
  },
  {
    link: "/teaching-schedule",
    title: "Lịch báo giảng",
    breadcrumbTitle: "Lịch báo giảng",
  },
  {
    link: "/homework",
    title: "Bài tập",
    breadcrumbTitle: "Bài tập",
  },
  {
    link: "/evaluate-structure",
    title: "Cấu trúc đánh giá",
    breadcrumbTitle: "Cấu trúc đánh giá",
  },
  {
    link: "/timetable-setting",
    title: "Thiết lập thời khóa biểu",
    breadcrumbTitle: "Thiết lập thời khóa biểu",
  },
  {
    link: "/timetable-auto-gen",
    title: "Thời khóa biểu tự động",
    breadcrumbTitle: "Thời khóa biểu tự động",
  },
  // Quản lý học sinh
  {
    link: "/admin/student-management",
    title: "Quản lý hồ sơ học sinh",
    breadcrumbTitle: "Danh sách học sinh",
  },
  {
    link: TEACHING_MANAGEMENT,
    title: "Quản lý giảng dạy",
    breadcrumbTitle: "Quản lý giảng dạy",
  },
  {
    link: PARENTS_MANAGEMENT,
    title: "Quản lý sổ điểm",
    breadcrumbTitle: "Quản lý sổ điểm",
  },
  {
    link: routes.TIMETABLE,
    title: "Thời khoá biểu",
    breadcrumbTitle: "Thời khoá biểu",
  },
  {
    link: routes.REGISTER_NOTEBOOKS,
    title: "Sổ đầu bài điện tử",
    breadcrumbTitle: "Sổ đầu bài điện tử",
  },
  {
    link: routes.TEACHING_DISTRIBUTION,
    title: "Phân công giảng dạy",
    breadcrumbTitle: "Phân công giảng dạy",
  },
  {
    link: STUDENT_MANAGEMENT,
    title: "Quản lý học sinh",
    breadcrumbTitle: "Quản lý học sinh",
  },
  {
    link: routes.STUDENT_INFO,
    title: "Thông tin học sinh",
    breadcrumbTitle: "Thông tin học sinh",
  },
  {
    link: routes.ATTENDANCE,
    title: "Điểm danh",
    breadcrumbTitle: "Điểm danh",
  },
  {
    link: routes.GRADEBOOK,
    title: "Bảng điểm",
    breadcrumbTitle: "Bảng điểm",
  },
  {
    link: routes.PARENT_CHECK_ATTENDANCE,
    title: "Phụ huynh xin phép",
    breadcrumbTitle: "Phụ huynh xin phép",
  },
  {
    link: routes.VIOLATE,
    title: "Đánh giá kỷ luật",
    breadcrumbTitle: "Đánh giá kỷ luật",
  },
  {
    link: routes.EVALUATION,
    title: "Vi phạm",
    breadcrumbTitle: "Vi phạm",
  },
  {
    link: routes.MESSAGE,
    title: "Thông báo",
    breadcrumbTitle: "Thông báo",
  },
  {
    link: "/personal",
    title: "Quản lý hồ sơ học sinh",
    breadcrumbTitle: "Chi tiết",
  },
  {
    link: "/personal/edit",
    title: "Quản lý hồ sơ học sinh",
    breadcrumbTitle: "Chi tiết",
  },
  {
    link: "/search-school-record",
    title: "Tra cứu học bạ",
    breadcrumbTitle: "Tra cứu học bạ",
  },
  {
    link: "/search-school-record-detail",
    title: "Chi tiết học bạ",
    breadcrumbTitle: "Chi tiết học bạ",
  },
  // {
  //   link: "/attendance",
  //   title: "Điểm danh",
  //   breadcrumbTitle: "Điểm danh",
  // },
  {
    link: "/transcript",
    title: "Theo dõi và đánh giá",
    breadcrumbTitle: "Bảng điểm",
  },
  {
    link: "/capacity-quality",
    title: "Năng lực, phẩm chất",
    breadcrumbTitle: "Năng lực, phẩm chất",
  },
  {
    link: "/transcript-detail",
    title: "Theo dõi và đánh giá",
    breadcrumbTitle: "Chi tiết lớp",
  },
  {
    link: "/study-summary",
    title: "Tổng kết",
    breadcrumbTitle: "Tổng kết",
  },
  // Quản lý nhân sự
  {
    link: "/admin/staff-management",
    title: "Quản lý hồ sơ giáo viên",
    breadcrumbTitle: "Danh sách giáo viên",
  },
  {
    link: "/teacher-detail",
    title: "Quản lý hồ sơ giáo viên",
    breadcrumbTitle: "Chi tiết",
  },
  {
    link: "/teacher-detail/edit",
    title: "Quản lý hồ sơ giáo viên",
    breadcrumbTitle: "Chi tiết",
  },
  {
    link: "/teaching-group",
    title: "Tổ giảng dạy",
    breadcrumbTitle: "Tổ giảng dạy",
  },
  {
    link: "/teaching-group/edit",
    title: "Chi tiết tổ giảng dạy",
    breadcrumbTitle: "Chi tiết tổ giảng dạy",
  },
  // Quản lý khen thưởng
  {
    link: "/certificate-of-merit",
    title: "Khen thưởng học sinh",
    breadcrumbTitle: "Khen thưởng học sinh",
  },
  // Quản lý tài khoản
  {
    link: "/user-management",
    title: "Tài khoản",
    breadcrumbTitle: "Tài khoản",
  },
  // Quản lý khoản thu
  {
    link: "/receipt-management",
    title: "Danh sách khoản thu",
    breadcrumbTitle: "Danh sách khoản thu",
  },
  {
    link: "/collect-revenue",
    title: "Danh sách thu",
    breadcrumbTitle: "Danh sách thu",
  },
  {
    link: "/revenue-detail",
    title: "Thiết lập khoản thu",
    breadcrumbTitle: "Thiết lập khoản thu",
  },
  {
    link: "/receipt-item",
    title: "Danh mục khoản thu",
    breadcrumbTitle: "Danh mục khoản thu",
  },
  {
    link: "/category",
    title: "Loại danh mục",
    breadcrumbTitle: "Loại danh mục",
  },
  // Quản lý cơ sở vật chất
  {
    link: "/area-structure",
    title: "Cấu trúc khu vực",
    breadcrumbTitle: "Cấu trúc khu vực",
  },
  {
    link: "/room-map",
    title: "Sơ đồ phòng",
    breadcrumbTitle: "Sơ đồ phòng",
  },

  // Thiết lập hệ thống
  {
    link: "/setting",
    title: "Thiết lập hệ thống",
    breadcrumbTitle: "Thiết lập hệ thống",
  },
  // Cổng thông tin
  {
    link: "/student/edit",
    title: "Thông tin học sinh",
    breadcrumbTitle: "Thông tin học sinh",
  },
  {
    link: "/learning-information",
    title: "Thông tin học tập",
    breadcrumbTitle: "Thông tin học tập",
  },
  {
    link: "/learning-outcomes",
    title: "Kết quả học tập",
    breadcrumbTitle: "Kết quả học tập",
  },
  {
    link: "/homework-info",
    title: "Thông tin bài tập",
    breadcrumbTitle: "Thông tin bài tập",
  },
  {
    link: "/student-schedule",
    title: "Thời khóa biểu",
    breadcrumbTitle: "Thời khóa biểu",
  },
  // Cá nhân (header)
  {
    link: "/personal-page",
    title: "Thông tin cá nhân",
    breadcrumbTitle: "Thông tin cá nhân",
  },
  {
    link: "/daily-tracking-medical",
    title: "Sổ theo dõi hằng ngày",
    breadcrumbTitle: "Sổ theo dõi hằng ngày",
  },
  {
    link: "/schools",
    title: "Danh sách trường",
    breadcrumbTitle: "Danh sách trường",
  },
  {
    link: "/profession-assessment-criteria",
    title: "Đánh giá chuẩn nghề nghiệp",
    breadcrumbTitle: "Đánh giá chuẩn nghề nghiệp",
  },
  {
    link: "/admissions-setting",
    title: "Thiết lập tuyển sinh",
    breadcrumbTitle: "Thiết lập tuyển sinh",
  },
  {
    link: "/admissions-route-config",
    title: "Phân tuyến tuyển sinh",
    breadcrumbTitle: "Phân tuyến tuyển sinh",
  },
  {
    link: "/profile-review",
    title: "Xét duyệt hồ sơ",
    breadcrumbTitle: "Xét duyệt hồ sơ",
  },
];

export const SIDEBAR_ITEMS = [
  {
    icon: sidebarAccount,
    activeIcon: sidebarAccountActive,
    iconAlt: "account",
    name: "Thông tin cá nhân",
    key: "personal_details",
    link: "/user/profile",
  },

  {
    icon: sidebarTeacher,
    activeIcon: sidebarTeacherActive,
    iconAlt: "teacher",
    name: "Quản lý nhân sự",
    key: "staffs_management",
    link: "/admin/staff-management",
  },
  {
    icon: sidebarStudent,
    activeIcon: sidebarStudentActive,
    iconAlt: "students",
    name: "Quản lý học sinh",
    key: "students_management",
    link: "/admin/student-management",
    children: [
      {
        name: "Thông tin học sinh",
        link: routes.STUDENT_INFO,
        key: "student-info",
      },
      {
        name: "Điểm danh",
        link: routes.ATTENDANCE,
        key: "attendance",
      },
      {
        name: "Bảng điểm",
        link: routes.GRADEBOOK,
        key: "transcript",
      },
      {
        name: "Phụ huynh xin phép",
        link: routes.PARENT_CHECK_ATTENDANCE,
        key: "parent-check-attendance",
      },
      {
        name: "Vi phạm",
        link: routes.EVALUATION,
        key: "evaluation",
      },
      {
        name: "Đánh giá kỷ luật",
        link: routes.VIOLATE,
        key: "violate",
      },
    ],
  },
  {
    icon: sidebarTeaching,
    activeIcon: sidebarTeachingActive,
    iconAlt: "students",
    name: "Quản lý giảng dạy",
    key: "teaching_management",
    link: TEACHING_MANAGEMENT,
    children: [
      {
        name: "Thời khoá biểu",
        link: routes.TIMETABLE,
        key: "timetable",
      },
      {
        name: "Phân công giảng dạy",
        link: routes.TEACHING_DISTRIBUTION,
        key: "teaching-distribution",
      },
      {
        name: "Sổ đầu bài điện tử",
        link: routes.REGISTER_NOTEBOOKS,
        key: "register-notebooks",
      },
      {
        name: "Hỗ trợ chấm bài",
        link: routes.GRADER_SUPPORT,
        key: "grader-support",
      },
      {
        name: "Sổ liên lạc",
        link: routes.CONTACT_BOOK,
        key: "parents-contact",
      },
    ],
  },

  {
    icon: sidebarInfrastructure,
    activeIcon: sidebarInfrastructureActive,
    iconAlt: "sms",
    name: "Quản lý thông báo",
    key: "announcements_management",
    link: "/admin/sms",
  },
  {
    icon: sidebarInfrastructure,
    activeIcon: sidebarInfrastructureActive,
    iconAlt: "message",
    name: "Thông báo",
    key: "messages_management",
    link: routes.MESSAGE,
  },
  // {
  //   icon: sidebarInfrastructure,
  //   activeIcon: sidebarInfrastructureActive,
  //   iconAlt: "message",
  //   name: "Quản lý nhà ăn",
  //   key: "canteen_management",
  //   link: routes.ADMIN_CANTEEN,
  //   children: [
  //     // {
  //     //   name: "Danh sách món ăn",
  //     //   link: routes.CANTEEN_DETAIL,
  //     //   key: "canteen_management",
  //     // },

  //     {
  //       name: "Danh sách món ăn tuần",
  //       link: routes.ADMIN_CANTEEN,
  //       key: "weekly_dish_list",
  //     },
  //     {
  //       name: "Danh sách học sinh đăng ký",
  //       link: "/canteen-management/lunch-register",
  //       key: "lunch_registry",
  //     },
  //   ],
  // },
  // {
  //   icon: sidebarInfrastructure,
  //   activeIcon: sidebarInfrastructureActive,
  //   iconAlt: "message",
  //   name: "Đăng ký ăn trưa",
  //   key: "lunch_register",
  //   link: "/lunch-register",
  // },
  // {
  //   icon: sidebarInfrastructure,
  //   activeIcon: sidebarInfrastructureActive,
  //   iconAlt: "message",
  //   name: "Quản lý nhà ăn",
  //   key: "canteen_management_week",
  //   link: routes.CANTEEN_WEEK,
  //   children: [
  //     // {
  //     //   name: "Danh sách món ăn",
  //     //   link: routes.CANTEEN_DETAIL,
  //     //   key: "canteen_management_week",
  //     // },

  //     {
  //       name: "Danh sách món ăn tuần",
  //       link: routes.CANTEEN_WEEK,
  //       key: "weekly_dish_list",
  //     },
  //     {
  //       name: "Danh sách học sinh đăng ký",
  //       link: "/canteen-management/lunch-register",
  //       key: "lunch_registry",
  //     },
  //   ],
  // },

  // {
  //   icon: sidebarSearch,
  //   activeIcon: sidebarSearchActive,
  //   iconAlt: "search",
  //   name: "Tra cứu",
  //   link: "/search",
  //   key: "search",
  // },
  // {
  //   icon: schoolIcon,
  //   activeIcon: schoolIconActive,
  //   iconAlt: "schools",
  //   name: "Danh sách trường",
  //   link: "/schools",
  //   key: "school-manager",
  // },
  // {
  //   icon: sidebarOverview,
  //   activeIcon: sidebarOverviewActive,
  //   iconAlt: "overview",
  //   name: "Tổng quan",
  //   link: "/home",
  //   key: "overview",
  // },
  // {
  //   icon: sidebarSchool,
  //   activeIcon: sidebarSchoolActive,
  //   iconAlt: "school",
  //   name: "Quản lý trường học",
  //   key: "school-info",
  //   link: "/school",
  // },
  // {
  //   icon: sidebarTeaching,
  //   activeIcon: sidebarTeachingActive,
  //   iconAlt: "teaching",
  //   name: "Quản lý giảng dạy",
  //   key: "teaching",
  //   children: [
  //     {
  //       name: "Phân phối chương trình",
  //       link: "/curriculum-distribution",
  //       key: "curriculum-distribution",
  //     },
  //     {
  //       name: "Phân công giảng dạy",
  //       link: "/teaching-distribution",
  //       key: "teaching-distribution",
  //     },
  //     {
  //       name: "Thời khóa biểu",
  //       link: "/timetable-information",
  //       key: "timetable-information",
  //     },
  //     {
  //       name: "Lịch báo giảng",
  //       link: "/teaching-schedule",
  //       key: "teaching-schedule",
  //     },
  //     {
  //       name: "Bài tập",
  //       link: "/homework",
  //       key: "homework",
  //     },
  //     {
  //       name: "Bài tập (LMS)",
  //       link: "",
  //       key: "homework_cohota",
  //     },
  //   ],
  // },
  // {
  //   icon: timetable,
  //   activeIcon: timetableActive,
  //   iconAlt: "timetable",
  //   name: "Thời khóa biểu tự động",
  //   key: "timetable",
  //   // isPremium: true,
  //   children: [
  //     {
  //       name: "Thiết lập thời khóa biểu",
  //       link: "/timetable-setting",
  //       key: "timetable-setting",
  //     },
  //     {
  //       name: "Tạo thời khóa biểu tự động",
  //       link: "/timetable-auto-gen",
  //       key: "timetable-auto-gen",
  //     },
  //   ],
  // },
  // {
  //   icon: sidebarSchool,
  //   activeIcon: sidebarSchoolActive,
  //   iconAlt: "admissions",
  //   name: "Tuyển sinh",
  //   key: "admissions",
  //   children: [
  //     {
  //       name: "Thiết lập",
  //       link: "/admissions-setting",
  //       key: "admissions-setting",
  //     },
  //     {
  //       name: "Phân tuyến tuyển sinh",
  //       link: "/admissions-route-config",
  //       key: "admissions-route-config",
  //     },
  //     {
  //       name: "Xét duyệt hồ sơ",
  //       link: "/profile-review",
  //       key: "profile-review",
  //     },
  //   ],
  // },
  // {
  //   icon: certificate,
  //   activeIcon: certificateActive,
  //   iconAlt: "certificate-of-merit",
  //   name: "Khen thưởng học sinh",
  //   link: "/certificate-of-merit",
  //   key: "certificate-of-merit",
  // },
  // {
  //   icon: sidebarCash,
  //   activeIcon: sidebarCashActive,
  //   iconAlt: "cash",
  //   name: "Quản lý khoản thu",
  //   link: "/home",
  //   key: "cash",
  //   children: [
  //     {
  //       name: "Danh sách khoản thu",
  //       link: "/receipt-management",
  //       key: "receipt-management",
  //     },
  //     {
  //       name: "Danh mục khoản thu",
  //       link: "/receipt-item",
  //       key: "receipt-item",
  //     },
  //     {
  //       name: "Loại danh mục",
  //       link: "/category",
  //       key: "category",
  //     },
  //   ],
  // },
  // {
  //   icon: sidebarInfrastructure,
  //   activeIcon: sidebarInfrastructureActive,
  //   iconAlt: "infrastructure",
  //   name: "Quản lý cơ sở vật chất",
  //   link: "/home",
  //   key: "infrastructure",
  //   children: [
  //     {
  //       name: "Cấu trúc khu vực",
  //       link: "/area-structure",
  //       key: "area-structure",
  //     },
  //     // {
  //     //   name: "Thiết bị - vật tư",
  //     //   link: "/utility",
  //     //   key: "utility",
  //     // },
  //     // {
  //     //   name: "Sơ đồ phòng",
  //     //   link: "/room-map",
  //     //   key: "room-map",
  //     // },
  //   ],
  // },
  // {
  //   icon: sidebarMedical,
  //   activeIcon: sidebarMedicalActive,
  //   iconAlt: "medical",
  //   name: "Y tế",
  //   link: "/home",
  //   key: "medical",
  //   children: [
  //     {
  //       name: "Sổ theo dõi hằng ngày",
  //       link: "/daily-tracking-medical",
  //       key: "daily-tracking-medical",
  //     },
  //     {
  //       name: "Khám sức khỏe định kỳ",
  //       link: "/periodic-health-check",
  //       key: "periodic-health-check",
  //     },
  //     {
  //       name: "Sổ sức khỏe",
  //       link: "/health-book",
  //       key: "health-book",
  //     },
  //   ],
  // },
  // {
  //   icon: sidebarSetting,
  //   activeIcon: sidebarSettingActive,
  //   iconAlt: "setting",
  //   name: "Thiết lập hệ thống",
  //   link: "/setting",
  //   key: "setting",
  // },
  // {
  //   icon: sidebarInfoGate,
  //   activeIcon: sidebarInfoGateActive,
  //   iconAlt: "info-gate",
  //   name: "Cổng thông tin",
  //   key: "info-gate",
  //   children: [
  //     // {
  //     //   name: "Thông tin học sinh",
  //     //   link: "/student/edit",
  //     //   key: "student-edit",
  //     // },
  //     {
  //       name: "Bài tập",
  //       link: "/learning-information",
  //       key: "learning-information",
  //     },
  //     {
  //       name: "Kết quả học tập",
  //       link: "/learning-outcomes",
  //       key: "learning-outcomes",
  //     },
  //     {
  //       name: "Thời khóa biểu",
  //       link: "/student-schedule",
  //       key: "student-schedule",
  //     },
  //     {
  //       name: "Bài tập (LMS)",
  //       link: "https://educentric.cohota.com",
  //       key: "homework_cohota",
  //     },
  //   ],
  // },
];

export const SO_PHONG_SIDEBAR_ITEMS = [
  {
    icon: sidebarOverview,
    activeIcon: sidebarOverviewActive,
    iconAlt: "overview",
    name: "Tổng quan",
    key: "overview",
    children: [
      {
        name: "Khối Tiểu học",
        link: "/overview-primary-school",
        key: "overview-primary-school",
      },
      {
        name: "Khối THCS",
        link: "/overview-secondary-school",
        key: "overview-secondary-school",
      },
      {
        name: "Khối Tiểu họczzzz",
        link: "/home",
        key: "overview",
      },
    ],
  },
];

export const SIDEBAR_ITEMS_UNIVERSITY = [
  {
    icon: sidebarTeaching,
    activeIcon: sidebarTeachingActive,
    iconAlt: "teaching",
    name: "Quản lý giảng dạy",
    key: "teaching",
    children: [
      {
        name: "Cấu trúc môn học",
        link: "/curriculum-distribution",
        key: "curriculum-distribution",
      },
      {
        name: "Phân công giảng dạy",
        link: "/teaching-distribution",
        key: "teaching-distribution",
      },
      {
        name: "Thời khóa biểu",
        link: "/timetable-information",
        key: "timetable-information",
      },
      {
        name: "Lịch báo giảng",
        link: "/teaching-schedule",
        key: "teaching-schedule",
      },
    ],
  },
  {
    icon: timetable,
    activeIcon: timetableActive,
    iconAlt: "timetable",
    name: "Thời khóa biểu tự động",
    key: "timetable",
    isPremium: true,
    children: [
      {
        name: "Thiết lập thời khóa biểu",
        link: "/timetable-setting",
        key: "timetable-setting",
      },
      {
        name: "Tạo thời khóa biểu tự động",
        link: "/timetable-auto-gen",
        key: "timetable-auto-gen",
      },
    ],
  },
  {
    icon: sidebarStudent,
    activeIcon: sidebarStudentActive,
    iconAlt: "students",
    name: "Quản lý sinh viên",
    key: "students",
    children: [
      {
        name: "Thông tin sinh viên",
        link: "/student-management",
        key: "student-management",
      },
    ],
  },
  {
    icon: sidebarTeacher,
    activeIcon: sidebarTeacherActive,
    iconAlt: "teacher",
    name: "Quản lý nhân sự",
    key: "teacher",
    children: [
      {
        name: "Thông tin nhân sự",
        link: "/staff-management",
        key: "staff-management",
      },
      {
        name: "Khoa",
        link: "/teaching-group",
        key: "teaching-group",
      },
    ],
  },
  {
    icon: sidebarInfrastructure,
    activeIcon: sidebarInfrastructureActive,
    iconAlt: "infrastructure",
    name: "Quản lý cơ sở vật chất",
    link: "/home",
    key: "infrastructure",
    children: [
      {
        name: "Cấu trúc khu vực",
        link: "/area-structure",
        key: "area-structure",
      },
    ],
  },
  {
    icon: sidebarSetting,
    activeIcon: sidebarSettingActive,
    iconAlt: "setting",
    name: "Thiết lập hệ thống",
    link: "/setting",
    key: "setting",
  },
];

export const SETTING_SUBSIDEBAR_ITEMS = [
  {
    id: "schoolYearScene",
    name: "Thiết lập niên khóa",
    key: "school_year",
  },
  // {
  //   id: "phongSoManagement",
  //   name: "Thiết lập Sở & Phòng",
  //   key: "phong_so_setting",
  //   children: [
  //     {
  //       fatherId: "phongSoManagement",
  //       id: "soScene",
  //       name: "Sở",
  //       key: "so",
  //     },
  //     {
  //       fatherId: "phongSoManagement",
  //       id: "phongScene",
  //       name: "Phòng",
  //       key: "phong",
  //     },
  //   ],
  // },
  {
    id: "schoolManagement",
    name: "Thiết lập trường học",
    key: "school_setting",
    children: [
      {
        fatherId: "schoolManagement",
        id: "gradeScene",
        name: "Khối",
        key: "grade",
      },
      {
        fatherId: "schoolManagement",
        id: "classScene",
        name: "Cấu trúc khối lớp",
        key: "class",
      },
    ],
  },
  {
    id: "studentManagement",
    name: "Thiết lập học sinh",
    key: "student_setting",
    children: [
      {
        fatherId: "studentManagement",
        id: "disciplineReasonScene",
        name: "Lý do kỷ luật",
        key: "disciplinary_reason",
      },
    ],
  },
  {
    id: "teachingManagement",
    name: "Thiết lập giảng dạy",
    key: "teaching_setting",
    children: [
      {
        fatherId: "teachingManagement",
        id: "subjectScene",
        name: "Môn học",
        key: "subject",
      },
      {
        fatherId: "teachingManagement",
        id: "distributionSubjectScene",
        name: "Chương trình học",
        key: "curriculum",
      },
      {
        fatherId: "teachingManagement",
        id: "distributionSubjectStructureScene",
        name: "Cấu trúc PPCT",
        key: "subject_structure",
      },
      {
        fatherId: "teachingManagement",
        id: "evaluateStructureScene",
        name: "Cấu trúc đánh giá",
        key: "score_group",
      },
      {
        fatherId: "teachingManagement",
        id: "commentScene",
        name: "Thiết lập nhận xét",
        key: "comment_setting",
      },
    ],
  },
  // {
  //   id: "humanResourceManagement",
  //   name: "Thiết lập nhân sự",
  //   key: "human_resource_setting",
  //   children: [
  //     {
  //       fatherId: "humanResourceManagement",
  //       id: "employeeTypeScene",
  //       name: "Loại nhân viên",
  //       key: "type_staff",
  //     },
  //     {
  //       fatherId: "humanResourceManagement",
  //       id: "employeeTitleScene",
  //       name: "Chức vụ",
  //       key: "staff_position",
  //     },
  //   ],
  // },
  {
    id: "scrollScene",
    name: "Công văn - Văn bản",
    key: "evaluate_method",
  },
  {
    id: "infrastructureManagement",
    name: "Cơ sở vật chất",
    key: "infrastructure_setting",
    children: [
      {
        fatherId: "infrastructureManagement",
        id: "areaScene",
        name: "Quản lý khu vực",
        key: "area_management",
      },
      {
        fatherId: "infrastructureManagement",
        id: "floorScene",
        name: "Quản lý tầng",
        key: "floor_management",
      },
      {
        fatherId: "infrastructureManagement",
        id: "featureScene",
        name: "Quản lý chức năng",
        key: "area_function",
      },
      {
        fatherId: "infrastructureManagement",
        id: "conditionScene",
        name: "Tình trạng",
        key: "area_condition",
      },
      {
        fatherId: "infrastructureManagement",
        id: "statusScene",
        name: "Trạng thái",
        key: "area_status",
      },
    ],
  },
  // {
  //   id: "medicalManagement",
  //   name: "Y tế",
  //   key: "medical_setting",
  //   children: [
  //     {
  //       fatherId: "medicalManagement",
  //       id: "diseaseListScene",
  //       name: "Danh mục bệnh",
  //       key: "medical_disease_list",
  //     },
  //     {
  //       fatherId: "medicalManagement",
  //       id: "solutionScene",
  //       name: "Hướng xử lý",
  //       key: "medical_solution",
  //     },
  //     {
  //       fatherId: "medicalManagement",
  //       id: "vaccineScene",
  //       name: "Vắc-xin",
  //       key: "medical_vaccine",
  //     },
  //   ],
  // },
  {
    id: "roleScene",
    name: "Phân quyền",
    key: "user_permission",
  },
  // {
  //   id: "dataTransferManagement",
  //   name: "Kết chuyển dữ liệu",
  //   key: "data_transfer",
  //   children: [
  //     {
  //       fatherId: "dataTransferManagement",
  //       id: "dataTransferClassScene",
  //       name: "Cấu trúc khối lớp",
  //       key: "data_transfer_class",
  //     },
  //     {
  //       fatherId: "dataTransferManagement",
  //       id: "dataTransferStudentScene",
  //       name: "Học sinh",
  //       key: "data_transfer_student",
  //     },
  //   ],
  // },
];

export const SETTING_SUBSIDEBAR_ITEMS_UNIVERSITY = [
  {
    id: "schoolYearScene",
    name: "Thiết lập niên khóa",
    key: "school_year",
  },
  {
    id: "schoolManagement",
    name: "Thiết lập trường học",
    key: "school_setting",
    children: [
      {
        fatherId: "schoolManagement",
        id: "gradeScene",
        name: "Khoa",
        key: "grade",
      },
      {
        fatherId: "schoolManagement",
        id: "classScene",
        name: "Lớp",
        key: "class",
      },
    ],
  },
  {
    id: "teachingManagement",
    name: "Thiết lập giảng dạy",
    key: "teaching_setting",
    children: [
      {
        fatherId: "teachingManagement",
        id: "subjectScene",
        name: "Môn học",
        key: "subject",
      },
      {
        fatherId: "teachingManagement",
        id: "distributionSubjectScene",
        name: "Kế hoạch giảng dạy",
        key: "curriculum",
      },
      {
        fatherId: "teachingManagement",
        id: "distributionSubjectStructureScene",
        name: "Thiết lập Cấu trúc môn học",
        key: "subject_structure",
      },
    ],
  },
  {
    id: "infrastructureManagement",
    name: "Cơ sở vật chất",
    key: "infrastructure_setting",
    children: [
      {
        fatherId: "infrastructureManagement",
        id: "areaScene",
        name: "Quản lý khu vực",
        key: "area_management",
      },
      {
        fatherId: "infrastructureManagement",
        id: "floorScene",
        name: "Quản lý tầng",
        key: "floor_management",
      },
      {
        fatherId: "infrastructureManagement",
        id: "featureScene",
        name: "Quản lý chức năng",
        key: "area_function",
      },
      {
        fatherId: "infrastructureManagement",
        id: "conditionScene",
        name: "Tình trạng",
        key: "area_condition",
      },
      {
        fatherId: "infrastructureManagement",
        id: "statusScene",
        name: "Trạng thái",
        key: "area_status",
      },
    ],
  },
  {
    id: "roleScene",
    name: "Phân quyền",
    key: "user_permission",
  },
];
