import { Modal, Spin } from "antd";
import InfoFieldDatePicker from "common/CusInfoField/infoFieldDatePicker/InfoFieldDatePicker";
import InfoFieldSelect from "common/CusInfoField/infoFieldSelect/InfoFieldSelect";
import moment from "moment";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { studentsViolateRulesService } from "services/studentsViolateRulesService";
import { getClassListByGrade, getGradeList } from "util/APIs";
import { isFromDateBeforeToDate } from "util/common";
import styles from "../styles.module.css";

const ExportDisciplinaryModal = memo(({ isModalOpen, onCloseModal }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const [loading, setLoading] = useState(false);
  const [fromDateExport, setFromDateExport] = useState(null);
  const [toDateExport, setToDateExport] = useState(null);
  const [gradeExport, setGradeExport] = useState(null);
  const [gradeList, setGradeList] = useState([]);
  const [classExport, setClassExport] = useState(null);
  const [classList, setClassList] = useState([]);

  const handleCancel = () => {
    setFromDateExport(null);
    setToDateExport(null);
    onCloseModal();
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const params = {
        "filters[classes.id]": classExport,
        "filters[date][$gte]": moment(fromDateExport).format("YYYY-MM-DD"),
        "filters[date][$lte]": moment(toDateExport).format("YYYY-MM-DD"),
      };
      const res = await studentsViolateRulesService.exportStudentsViolateRules(
        accountId,
        params
      );
      if (res.status === 200) {
        window.open(res.data.data, "_blank");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      handleCancel();
    }
  };

  const handleSetGrade = (value) => {
    setClassExport(null);
    setGradeExport(value);
  };

  const handleSelectDateFromExport = (value) => {
    const temp = moment(value).format("YYYY-MM-DD");
    setFromDateExport(temp);
  };

  const handleSelectDateToExport = (value) => {
    const temp = moment(value).format("YYYY-MM-DD");
    setToDateExport(temp);
  };

  const disabledBtn = useMemo(() => {
    return (
      !classExport || !isFromDateBeforeToDate(fromDateExport, toDateExport)
    );
  }, [classExport, fromDateExport, toDateExport]);

  const getClass = useCallback(
    async (gradeId) => {
      const params = {
        orderBy: "name",
      };
      const res = await getClassListByGrade(accountId, gradeId, params);
      if (res?.status === 200) {
        const data = res?.data?.data;
        setClassList(data);
        if (data?.length > 0) {
          setClassExport(data[0]?.id);
        }
      }
    },
    [accountId]
  );

  const getGrade = useCallback(async () => {
    const params = {
      orderBy: "name",
    };
    const res = await getGradeList(accountId, params);
    if (res?.status === 200) {
      const data = res?.data?.data;
      setGradeList(data);
      if (data?.length > 0) {
        setGradeExport(data[0]?.id);
      }
    }
  }, [accountId]);

  useEffect(() => {
    if (isModalOpen) {
      getGrade();
      setFromDateExport(moment().subtract(1, "months").format("YYYY-MM-DD"));
      setToDateExport(moment().format("YYYY-MM-DD"));
    }
  }, [getGrade, isModalOpen]);

  useEffect(() => {
    if (isModalOpen && gradeExport) {
      getClass(gradeExport);
    }
  }, [gradeExport, getClass, isModalOpen]);

  return (
    <Modal
      style={{ marginTop: "-70px" }}
      width={1000}
      title="Xuất báo cáo trật tự kỷ luật"
      open={isModalOpen}
      onOk={loading ? null : handleOk}
      destroyOnClose={true}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: disabledBtn,
        className: disabledBtn ? styles.disabledBtn : "",
      }}
      maskClosable={false}
    >
      <Spin spinning={loading} tip="Đang xuất dữ liệu. Vui lòng đợi...">
        <div className="flex flex-col gap-4">
          <InfoFieldSelect
            label="Chọn khối"
            data={gradeList}
            value={gradeExport}
            setValue={handleSetGrade}
            direction="vertical"
          />
          <InfoFieldSelect
            label="Chọn lớp"
            data={classList}
            value={classExport}
            setValue={setClassExport}
            direction="vertical"
          />
          <InfoFieldDatePicker
            label={"Từ Ngày"}
            value={fromDateExport}
            setValue={handleSelectDateFromExport}
            placeholder="Chọn thời gian"
            direction="vertical"
            className="flex-1"
            picker={"date"}
          />
          <InfoFieldDatePicker
            label={"Tới ngày"}
            value={toDateExport}
            setValue={handleSelectDateToExport}
            placeholder="Chọn thời gian"
            direction="vertical"
            className="flex-1"
            picker={"date"}
          />
          {!isFromDateBeforeToDate(fromDateExport, toDateExport) && (
            <span className="text-red-500">
              * Ngày bắt đầu không thể lớn hơn ngày kết thúc
            </span>
          )}
        </div>
      </Spin>
    </Modal>
  );
});

export default ExportDisciplinaryModal;
