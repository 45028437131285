import { Button, Space } from "antd";
import { Notification } from "common/Notification";
import { useAttendanceConext } from "page/diemDanh/Context";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { updateAttendanceOneStudent } from "util/APIs";
import { ATTENDANCE_STATUS, STATUS_ATTENDANCE } from "util/constant";
import styles from "../../../styles.module.css";

const WorkflowState = memo(({ value, record }) => {
  const userInfo = useSelector((state) => state?.app?.userInfo);
  const accountId = userInfo?.account?.id;
  const { selectedDate, setIsRefresh } = useAttendanceConext();
  const [loading, setLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    value?.attendances[0]?.workflow_state
  ); // workflow_state

  const handleAttendanceCheck = useCallback(
    async (isChecked, data, attendance_status) => {
      const workflowStateValue = isChecked
        ? attendance_status
        : STATUS_ATTENDANCE.unmarked;
      setLoading(true);
      const item = {
        date: selectedDate,
        students: data?.id,
        sessions: data?.attendances[0].sessions?.[0].id,
        // lessons: data?.lessons?.[0].id,
        workflow_state: workflowStateValue,
        staff: [userInfo?.user?.id],
      };
      const payload = {
        data: item,
      };
      console.log(
        "isChecked, data, attendance_status",
        isChecked,
        data,
        attendance_status
      );

      try {
        const res = await updateAttendanceOneStudent(
          accountId,
          data?.attendances[0]?.id,
          payload
        );
        if (res?.status === 200) {
          setCurrentStatus(workflowStateValue);
          // setIsRefresh?.(true);
          Notification.sendSuccess("Điểm danh thành công");
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [accountId, selectedDate, userInfo?.user?.id]
  );

  const isLate = useMemo(() => {
    return currentStatus === STATUS_ATTENDANCE.late;
  }, [currentStatus]);

  const isAnualLeave = useMemo(() => {
    return currentStatus === STATUS_ATTENDANCE.author_absent;
  }, [currentStatus]);

  const isPresent = useMemo(() => {
    return currentStatus === STATUS_ATTENDANCE.present;
  }, [currentStatus]);

  const isNoAuthLeave = useMemo(() => {
    return currentStatus === STATUS_ATTENDANCE.unauthor_absent;
  }, [currentStatus]);

  return (
    <Space size={"middle"}>
      <Button
        shape="circle"
        className={`late border-0 ${
          isPresent ? styles.presentActive : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(
                  !isPresent,
                  record,
                  ATTENDANCE_STATUS[0].key
                )
        }
      >
        C
      </Button>
      <Button
        shape="circle"
        className={`late border-0 ${
          isLate ? styles.lateActive : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(!isLate, record, ATTENDANCE_STATUS[1].key)
        }
      >
        T
      </Button>
      <Button
        shape="circle"
        className={`anualLeave border-0 ${
          isAnualLeave
            ? styles.anualLeaveActive
            : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(
                  !isAnualLeave,
                  record,
                  ATTENDANCE_STATUS[2].key
                )
        }
      >
        P
      </Button>
      <Button
        shape="circle"
        className={`noAuthLeave border-0 ${
          isNoAuthLeave
            ? styles.noAuthLeaveActive
            : styles.attendanceButtonDefault
        }`}
        disabled={loading}
        onClick={
          loading
            ? null
            : () =>
                handleAttendanceCheck(
                  !isNoAuthLeave,
                  record,
                  ATTENDANCE_STATUS[3].key
                )
        }
      >
        K
      </Button>
    </Space>
  );
});

export default WorkflowState;
